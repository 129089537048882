import { Checkbox } from '@pelotoncycle/design-system';
import { reduce, toPairs } from 'ramda';
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { PanelManagerContext, Panels } from '@acme-ui/global/PanelManagerProvider';
import Flyout from '@ecomm/flyout';
import { hb3, hb4 } from '@ecomm/typography';
import type { Toggles } from '../models/Toggles';
import { ToggleStatus } from '../models/ToggleStatus';

export const PREVIEW_COPY_TOGGLE_ID = 'previewCopy';

type Props = {
  shouldCreateWindowHandler: boolean;
  flyoutAriaLabel: string;
  setToggleValue: (toggleId: string, value: boolean) => void;
  isToggleActive: (toggleId: string) => boolean;
  toggles: Toggles<string>;
};

const FeatureTogglePanel: React.FC<React.PropsWithChildren<Props>> = ({
  flyoutAriaLabel,
  shouldCreateWindowHandler,
  isToggleActive,
  setToggleValue,
  toggles,
}) => {
  const { activePanel, setActivePanel } = useContext(PanelManagerContext);
  const [filter, setFilter] = useState('');

  const previewCopyValue = isToggleActive(PREVIEW_COPY_TOGGLE_ID);

  useEffect(() => {
    let timerId: number;
    const touchStartHandler = (ev: TouchEvent) => {
      timerId = window.setTimeout(() => setActivePanel(Panels.Toggles), 5000);
    };
    const touchEndHandler = (ev: TouchEvent) => {
      window.clearTimeout(timerId);
    };

    if (document) {
      document.addEventListener('keydown', (ev: KeyboardEvent) => {
        if (areAltAndCKeysPressed(ev)) {
          setToggleValue(PREVIEW_COPY_TOGGLE_ID, !previewCopyValue);
        }
      });
      document.addEventListener('touchstart', touchStartHandler);
      document.addEventListener('touchend', touchEndHandler);
    }

    return () => {
      window.clearTimeout(timerId);
      document.removeEventListener('touchstart', touchStartHandler);
      document.removeEventListener('touchend', touchEndHandler);
    };
  }, [previewCopyValue, setToggleValue, setActivePanel]);

  useEffect(() => {
    if (window && shouldCreateWindowHandler) {
      (window as any).__setToggle = (t: string, val: boolean) => setToggleValue(t, val);
    }
  }, [setToggleValue, shouldCreateWindowHandler]);

  const groupedToggles = reduce(
    (acc, [name, { original }]) => {
      acc[original] ? acc[original].push(name) : (acc[original] = [name]);
      return acc;
    },
    {},
    toPairs(toggles),
  );

  return (
    <Flyout
      isOpen={activePanel === Panels.Toggles}
      ariaLabel={flyoutAriaLabel}
      backgroundColor="#ffffff"
      handleRequestClose={() => setActivePanel(undefined)}
    >
      <ContentContainer data-test-id="togglePanel">
        <FeatureToggleTitle>Features</FeatureToggleTitle>

        <input
          value={filter}
          onChange={e =>
            setFilter(e.currentTarget.value.replace(/[^a-z0-9]/gi, '').toLowerCase())
          }
        />

        {toggleOrder.map(status => (
          <React.Fragment key={status}>
            <FeatureToggleSubtitle>{status}</FeatureToggleSubtitle>
            {(groupedToggles[status] || []).map(
              (toggle: string) =>
                toggle.toLowerCase().includes(filter) && (
                  <FeatureToggleCheckbox
                    id={toggle}
                    key={toggle}
                    toggleId={toggle}
                    isToggleActive={isToggleActive}
                    setToggleValue={setToggleValue}
                  />
                ),
            )}
          </React.Fragment>
        ))}
      </ContentContainer>
    </Flyout>
  );
};

export default FeatureTogglePanel;

const toggleOrder = [
  ToggleStatus.Optimizely,
  ToggleStatus.DateTrigger,
  ToggleStatus.DarkDateTrigger,
  ToggleStatus.Local,
  ToggleStatus.Dark,
  ToggleStatus.Enabled,
  ToggleStatus.Disabled,
];

const areAltAndCKeysPressed = (ev?: KeyboardEvent) =>
  ev && ev.altKey && (ev.code === 'KeyC' || ev.key === 'Ç' || ev.which === 67); // Alt-c on Windows or Option-c on Mac

const ContentContainer = styled.nav`
  padding: 50px 30px;
  width: 320px;

  > label {
    display: grid;
    margin: 10px 0;
  }
`;

const FeatureToggleTitle = styled.h1`
  ${hb3};
  margin-bottom: 15px;
`;

const FeatureToggleSubtitle = styled.h2`
  ${hb4};
  margin: 20px 0 10px 0;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 0;
`;

type CheckboxProps = Pick<Props, 'isToggleActive' | 'setToggleValue'> & {
  toggleId: string;
  id: string;
};

const FeatureToggleCheckbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  toggleId,
  isToggleActive,
  setToggleValue,
}) => {
  const isActive = isToggleActive(toggleId);

  return (
    <StyledCheckbox
      checked={isActive}
      handleChange={() => {
        setToggleValue(toggleId, !isActive);
      }}
      id={toggleId}
      key={toggleId}
      labelText={toggleId}
      name={toggleId}
    />
  );
};
