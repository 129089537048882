import React from 'react';
import { isUnavailableMarketingLeadCapture } from '@ecomm/product-states/models/availability';
import { ProductStatesContext } from './Context';
import type { AvailabilityByProductOrNull, VerifyProductState } from './Context';
import type { AvailabilityState, AvailabilityByProduct } from './models';
import {
  Product,
  ProductStates,
  isAvailableForPurchase,
  isAvailableForMarketingPages,
  isAvailableForShopPages,
  isUnavailableForPurchase,
} from './models';
import productStateUrlMap from './nextProductStateUrlMap';

export const buildContextAPI = (productStateInUse: AvailabilityByProduct) => {
  const getAvailability = (product: Product) => {
    let availabilityState = productStateInUse[product];
    // TODO: remove this mapping once all RainforestCafe usages are removed
    if (product === Product.RainforestCafe && !productStateInUse[product]) {
      availabilityState = productStateInUse['guide'];
    }
    return availabilityState;
  };

  const isRouteAvailable = (url: string): boolean => {
    // Due to secret items injected into the Product enum, and the funky data
    // resulting, the types may not actually be right here and the
    // AvailabilityState might be undefined. Passing an empty object as a default
    // should cover that.

    return (
      !isRouteControlledByProductToggles(url) ||
      productStateUrlMap[url](
        product => getAvailability(product) ?? { state: ProductStates.Unknown, code: 0 },
      )
    );
  };

  const verifyProductState: VerifyProductState = (product, acceptedStates): boolean => {
    const availabilityState = getAvailability(product);

    return availabilityState && acceptedStates.includes(availabilityState.state);
  };

  const verifyAvailability = (
    product: Product,
    predicate: (a: AvailabilityState) => boolean,
  ) => {
    const availabilityState = getAvailability(product);
    return availabilityState ? predicate(availabilityState) : false;
  };

  // Is purchasable or has ever been purchasable
  // TODO: [POSEIDON-37] - Create new Product State called UnavailableShopTemporary and
  // use the new state below instead of Lead Capture
  const isProductAvailableForPurchaseOrSoldOut = (product: Product) =>
    isProductAvailableForPurchase(product) ||
    verifyProductState(product, [ProductStates.UnavailableShopSoldOut]) ||
    verifyProductState(product, [ProductStates.UnavailableShopLeadCapture]);

  const isProductAvailableForMarketingPages = (product: Product) =>
    verifyAvailability(product, isAvailableForMarketingPages);

  const isProductAvailableForShopPages = (product: Product) =>
    verifyAvailability(product, isAvailableForShopPages);

  const isAnyCFUAvailableForPurchase = () =>
    Object.keys(productStateInUse)
      .filter(p => p !== Product.DigitalApp)
      .some((p: Product) => isProductAvailableForPurchase(p));

  const isProductAvailableForShopLeadCapture = (product: Product) =>
    isProductAvailableForShopPages(product) &&
    verifyProductState(product, [ProductStates.UnavailableShopLeadCapture]);

  const isRouteControlledByProductToggles = (pathname: string) => {
    return Object.prototype.hasOwnProperty.call(productStateUrlMap, pathname);
  };

  const isProductAvailableForPurchase = (product: Product) =>
    verifyAvailability(product, isAvailableForPurchase);

  const isProductUnavailableForPurchase = (product: Product) =>
    verifyAvailability(product, isUnavailableForPurchase);

  const isProductUnavailableMarketingLeadCapture = (product: Product) =>
    verifyAvailability(product, isUnavailableMarketingLeadCapture);

  const isProductUnavailableSoldOut = (product: Product) =>
    verifyProductState(product, [ProductStates.UnavailableShopSoldOut]);

  return {
    isRouteAvailable,
    verifyProductState,
    verifyAvailability,
    isProductAvailableForMarketingPages,
    isProductAvailableForShopPages,
    isProductAvailableForPurchase,
    isProductAvailableForPurchaseOrSoldOut,
    isRouteControlledByProductToggles,
    isAnyCFUAvailableForPurchase,
    isProductAvailableForShopLeadCapture,
    isProductUnavailableForPurchase,
    isProductUnavailableMarketingLeadCapture,
    isProductUnavailableSoldOut,
  };
};

export const BaseProductStatesProvider: React.FC<
  React.PropsWithChildren<{
    productStates: AvailabilityByProduct;
    overrideUserProductStates?: Boolean | undefined;
  }>
> = ({ children, productStates, overrideUserProductStates = false }) => {
  const [
    userProductStates,
    setUserProductStates,
  ] = React.useState<AvailabilityByProductOrNull>(null);
  const productStateInUse =
    userProductStates && !overrideUserProductStates ? userProductStates : productStates;

  const context = {
    ...buildContextAPI(productStateInUse),
    productStates: productStateInUse,
    setProductStates: setUserProductStates,
    areStatesOverridden: Boolean(userProductStates),
  };

  return (
    <ProductStatesContext.Provider value={context}>
      {children}
    </ProductStatesContext.Provider>
  );
};

export const ProductStatesProvider: React.FC<
  React.PropsWithChildren<{
    productState: AvailabilityByProduct;
  }>
> = ({ children, productState }) => {
  return (
    <BaseProductStatesProvider productStates={productState}>
      {children}
    </BaseProductStatesProvider>
  );
};
