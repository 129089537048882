import React from 'react';
import useSWR from 'swr';
import { IMMUTABLE } from '@content/client/revalidation';
import ProgramContext, { defaultProgramsData } from './Context';
import type { Programs } from './models';
import { programArray } from './models';

export const NextProgramProvider: React.FC<
  React.PropsWithChildren<Partial<Programs>>
> = ({ children, ...programOverrides }) => {
  const { data } = useSWR<Programs>('programs', IMMUTABLE);

  const contentfulPrograms = {
    ...data,
    ...programOverrides,
  };

  const initialPrograms: Programs = programArray.reduce(
    (acc, program) => ({
      ...acc,
      [program]: contentfulPrograms?.[program] ?? acc[program],
    }),
    defaultProgramsData,
  );

  const [programs, setProgramsFull] = React.useState<Programs>(initialPrograms);

  const setPrograms = (partialPrograms: Partial<Programs>) => {
    setProgramsFull(p => ({ ...p, ...partialPrograms }));
  };
  return (
    <ProgramContext.Provider
      value={{
        ...programs,
        setPrograms,
        initialPrograms,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};
