import type { AxiosInstance } from 'axios';
import React from 'react';
import { ClientProvider as PClientProvider } from '@peloton/api';
import useAuthorizationHeader from './useAuthorizationHeader';

const NextClientProvider: React.FC<
  React.PropsWithChildren<{ client: AxiosInstance }>
> = ({ children, client }) => {
  const clientHeadersReady = useAuthorizationHeader(client);
  return (
    <PClientProvider client={clientHeadersReady ? client : undefined}>
      {children}
    </PClientProvider>
  );
};

export default NextClientProvider;
