import React from 'react';
import { SWRConfig } from 'swr';
import { REVALIDATE_PREVIEW_ONLY } from '@content/client/revalidation';
import { defaultProgramsData } from '@ecomm/programs/Context';

type Props = {
  fallback: any; // TODO: Consider pageProps type in _app (currently any)
};

const CustomSWRConfig: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  fallback,
}) => {
  const fallbackWithDefaults = {
    ...fallback,
    programs: fallback?.programs ?? defaultProgramsData, // Programs data not fetched on 404s/500s so provide default data
  };

  return (
    <SWRConfig
      value={{
        fallback: fallbackWithDefaults,
        ...REVALIDATE_PREVIEW_ONLY,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default CustomSWRConfig;
