import React from 'react';
import { ALLOW_TOGGLE_PANEL } from '@peloton/app-config';
import {
  useGenericIsToggleActive,
  useGenericSetToggleValue,
  useToggles,
} from '@ecomm/feature-toggle/context/ToggleContext';
import PanelComponent from '@ecomm/feature-toggle/panel/View';

const ContextFeatureTogglePanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isToggleActive = useGenericIsToggleActive();
  const setToggleValue = useGenericSetToggleValue();
  const toggles = useToggles();

  return (
    <PanelComponent
      toggles={toggles}
      isToggleActive={isToggleActive}
      setToggleValue={setToggleValue}
      flyoutAriaLabel={'Toggle Panel'}
      shouldCreateWindowHandler={ALLOW_TOGGLE_PANEL}
    />
  );
};

export default ContextFeatureTogglePanel;
