import axios from 'axios';
import type { AxiosInstance } from 'axios';
import {
  toDefaultRequestTransforms,
  toDefaultResponseTransforms,
} from '@peloton/api/client-transforms';
import { ONEPELOTON_API_URL } from '@peloton/app-config';
import { getAPIAuthToken } from '@peloton/auth/config';

export type Client = AxiosInstance;

type ClientOpts = {
  headers?: Object;
  platform?: string;
  timeout?: number;
  authorizationHeaderEnabled?: boolean;
};

export const toClient = ({
  platform = 'web',
  headers = {},
  timeout,
  authorizationHeaderEnabled = false,
}: ClientOpts): Client => {
  const client = axios.create({
    baseURL: ONEPELOTON_API_URL,
    responseType: 'json',
    withCredentials: true,
    timeout,
    headers: {
      'X-Requested-With': 'XmlHttpRequest',
      'Peloton-Platform': platform,
      ...headers,
    },
    transformRequest: toDefaultRequestTransforms(),
    transformResponse: toDefaultResponseTransforms(),
  });

  if (authorizationHeaderEnabled) {
    client.interceptors.request.use(request => {
      const token = getAPIAuthToken();
      if (token) request.headers['X-Secrets-Authorization'] = token;
      return request;
    });
  }

  return client;
};
