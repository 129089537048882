import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import React from 'react';

type PrevRoutes = string | null;

function usePreviousRoute(): PrevRoutes {
  const { asPath }: NextRouter = useRouter();
  const ref = React.useRef<string | null>(null);

  React.useEffect(() => {
    const sameWithoutQuery = ref.current?.split('?')[0] === asPath.split('?')[0];

    if (!sameWithoutQuery) {
      ref.current = asPath;
    }
  }, [asPath]);

  return ref.current;
}

const PreviousRouteContext = React.createContext<PrevRoutes>(null);

export const PreviousRouteProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const previousRoute = usePreviousRoute();

  return (
    <PreviousRouteContext.Provider value={previousRoute}>
      {children}
    </PreviousRouteContext.Provider>
  );
};

export function usePreviousRouteContext() {
  return React.useContext(PreviousRouteContext);
}
