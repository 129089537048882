import type { AxiosInstance } from 'axios';
import { identity } from 'ramda';
import { pipeData } from '@peloton/api/utils';

import type { ApiMeUser } from './types';

export const getCurrentUser = async (
  client: AxiosInstance,
): Promise<ApiMeUser | undefined> => {
  try {
    const apiUser = await client.get('/api/me').then(pipeData(identity));
    return apiUser;
  } catch (e) {
    console.error(e);
    return;
  }
};
